import React from "react";
import Logo from "../Assets/logo.png";
import { ReactComponent as PaymentIcon } from "../Assets/icons/paymentIcon.svg";
import { ReactComponent as CloseIcon } from "../Assets/icons/closeIcon.svg";
import { ReactComponent as DashboardIcon } from "../Assets/icons/dashboardIcon.svg";
import { ReactComponent as BookingIcon } from "../Assets/icons/bookingIcon.svg";
import { ReactComponent as MembersIcon } from "../Assets/icons/membersIcon.svg";
import { ReactComponent as ManageIcon } from "../Assets/icons/manageIcon.svg";
import { ReactComponent as CommunicationsIcon } from "../Assets/icons/communicationIcon.svg";
import { ReactComponent as StaffIcon } from "../Assets/icons/staffIcon.svg";
import { ReactComponent as ReferralsIcon } from "../Assets/icons/referralIcon.svg";
import { ReactComponent as AuditIcon } from "../Assets/icons/auditLogIcon.svg";
import { ReactComponent as SettingIcon } from "../Assets/icons/settingsIcon.svg";

const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  return (
    <div className={openSidebar ? "" : "hidden md:flex"}>
      <div className="bg-white w-[14rem] h-screen fixed top-0 pt-3 border-r-2 border-[#EBEDF7] z-50">
        <CloseIcon
          className="visible md:invisible ml-[2vw] cursor-pointer"
          onClick={() => setOpenSidebar(false)}
        />
        <div>
          <div className="w-full flex justify-center">
            <img src={Logo} alt="logo" />
          </div>
          <div className="divide-y-2 divide-[#EBEDF7]">
            <div className="pt-6">
              <div>
                {[
                  {
                    name: "Dashboard",
                    icon: <DashboardIcon />,
                    active: true,
                  },
                  {
                    name: "Booking",
                    icon: <BookingIcon />,
                  },
                  {
                    name: "payments",
                    icon: <PaymentIcon />,
                  },
                  {
                    name: "members",
                    icon: <MembersIcon />,
                  },
                  {
                    name: "manage",
                    icon: <ManageIcon />,
                  },
                  {
                    name: "communications",
                    icon: <CommunicationsIcon />,
                  },
                  {
                    name: "staff",
                    icon: <StaffIcon />,
                  },
                ].map((item, index) => {
                  return (
                    <button className="w-full flex flex-row items-center my-1 pr-6 relative">
                      {item.active && (
                        <div className="w-1 h-full bg-[#4880FF] absolute top-0 left-0 rounded-r" />
                      )}
                      <div
                        className={`w-full flex flex-row items-center justify-start py-3.5 px-5 ml-5 rounded-md ${
                          item?.active ? "bg-[#E7EEFF]" : "bg-white"
                        }`}
                        key={index}
                      >
                        <span className="w-6">{item.icon}</span>
                        <span
                          className={`text-sm ml-2 capitalize ${
                            item?.active
                              ? "text-[#4880FF] font-semibold"
                              : "text-[#4C5274] font-medium"
                          }`}
                        >
                          {item.name}
                        </span>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="pt-[0.5vw] md:pt-[1vw]">
              <div>
                {[
                  {
                    name: "Referrals",
                    icon: <ReferralsIcon />,
                  },
                  {
                    name: "Audit logs",
                    icon: <AuditIcon />,
                  },
                  {
                    name: "Settings",
                    icon: <SettingIcon />,
                  },
                ].map((item, index) => {
                  return (
                    <button className="w-full flex flex-row items-center my-1 pr-6 relative">
                      {item.active && (
                        <div className="w-1 h-full bg-[#4880FF] absolute top-0 left-0 rounded-r" />
                      )}
                      <div
                        className={`w-full flex flex-row items-center justify-start py-3.5 px-5 ml-5 rounded-md ${
                          item?.active ? "bg-[#E7EEFF]" : "bg-white"
                        }`}
                        key={index}
                      >
                        <span className="w-6">{item.icon}</span>
                        <span
                          className={`text-sm ml-2 capitalize ${
                            item?.active
                              ? "text-[#4880FF] font-semibold"
                              : "text-[#4C5274] font-medium"
                          }`}
                        >
                          {item.name}
                        </span>
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
