import React from "react";
import Form from "../Components/Form";
import DashboardLayout from "../Layout/DashboardLayout";

const Dahsboard = () => {
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  return (
    <div className="w-full h-max min-h-[100vh] bg-[#F5F7FA]">
      <DashboardLayout
        openModal={openModal}
        setOpenModal={setOpenModal}
        openSidebar={open}
        setOpenSidebar={setOpen}
      >
        <Form open={openModal} setOpen={setOpenModal} />
      </DashboardLayout>
    </div>
  );
};

export default Dahsboard;
