import React, { useEffect, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

const Modal = ({
  open,
  setOpen,
  updateKaynaConfig,
  updateKaynaConfigItems,
  kaynaConfig,
  updateWidget,
  updateKaynaConfigStyle,
  updateKaynaConfigEditor,
  kaynaClaims,
  handleClaimState,
  platform,
  setPlatform,
}) => {
  const [editorStateConfig, setEditorStateConfig] = useState(
    JSON.stringify(kaynaConfig?.params, null, 2)
  );
  const [editorStateStyle, setEditorStateStyle] = useState(
    JSON.stringify(kaynaConfig?.style, null, 2)
  );
  const [editorStateKeys, setEditorStateKeys] = useState(
    JSON.stringify(kaynaConfig?.keys, null, 2)
  );
  const [editorStateClaims, setEditorStateClaims] = useState(
    JSON.stringify(kaynaConfig?.claims, null, 2)
  );
  const [JSONView, setJSONView] = useState("false");
  const [error, setError] = useState({
    errorConfig: null,
    errorStyle: null,
    errorKeys: null,
    errorClaims: null,
  });
  console.log(JSON.parse(editorStateClaims)?.claimId);
  // const handleSetJSONValue = (e) => {
  //   console.log("JSONVIEW SETTING: ", e.target.value);
  //   setJSONView(e.target.value);
  // };

  const handleStyle = (e) => {
    const parsed = JSON.parse(editorStateStyle);

    let kaynaConfigTemp = { ...parsed };
    kaynaConfigTemp[e.target.name] = e.target.value;

    setEditorStateStyle(JSON.stringify(kaynaConfigTemp));
  };

  const handleConfig = (e) => {
    const parsed = JSON.parse(editorStateConfig);

    let kaynaConfigTemp = { ...parsed };
    kaynaConfigTemp[e.target.name] = e.target.value;

    setEditorStateConfig(JSON.stringify(kaynaConfigTemp));
  };

  // const handleConfigItems = (e, type = "requiredParams") => {
  //   const parsed = JSON.parse(editorStateConfig);

  //   let kaynaConfigTemp = { ...parsed };
  //   kaynaConfigTemp.items[type][e.target.name] = e.target.value;

  //   setEditorStateConfig(JSON.stringify(kaynaConfigTemp));
  // };

  const handleClaimItems = (e, idx = null) => {
    const parsed = JSON.parse(editorStateClaims);

    let kaynaClaimsTemp = { ...parsed };

    if (idx === null) {
      console.log(e.target.value);
      setEditorStateClaims(
        JSON.stringify({ ...kaynaClaimsTemp, claimId: e.target.value }, null, 2)
      );
    } else {
      let claimItems = [...kaynaClaimsTemp.item];
      claimItems[idx][e.target.name] = e.target.value;
      setEditorStateClaims(
        JSON.stringify({ ...kaynaClaimsTemp, item: claimItems }, null, 2)
      );
    }
  };

  useEffect(() => {
    console.log("JSONVIEW: ", JSONView);
  }, [JSONView]);

  return (
    <div>
      {open && (
        <div
          id="authentication-modal"
          tabIndex={-1}
          aria-hidden="true"
          className="overflow-y-hidden overflow-x-hidden fixed top-0 left-0  z-50 inset-0 h-modal md:h-full bg-black bg-opacity-50"
        >
          <div className="relative w-full sm:w-fit h-full mx-auto mt-[5vw] md:h-auto overflow-y-auto">
            {/* Modal content */}
            <div className="w-fit m-auto relative bg-white rounded-lg shadow h-[90vh] overflow-y-auto">
              <div className="flex justify-end p-2">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  onClick={() => setOpen(false)}
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="px-6 pb-4 space-y-6 lg:px-8 sm:pb-6 xl:pb-8 w-full">
                <div className="flex justify-between gap-x-12 align-center items-center">
                  <h3 className="text-xl font-medium text-gray-900 flex-2">
                    Platform Data
                  </h3>

                  <button
                    className="w-fit flex-1 text-white bg-black hover:bg-black-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2 text-center "
                    onClick={async () => {
                      let config = null;
                      let style = null;
                      let keys = null;
                      let claims = null;
                      let errorParse = false;

                      setError({
                        errorConfig: null,
                        errorStyle: null,
                        errorKeys: null,
                      });

                      try {
                        const parsedConfig = JSON.parse(editorStateConfig);
                        config = parsedConfig;
                      } catch (error) {
                        errorParse = true;
                        setError({
                          ...error,
                          errorConfig: "Not a valid JSON.",
                        });
                      }
                      try {
                        const parsedStyle = JSON.parse(editorStateStyle);
                        style = parsedStyle;
                      } catch (error) {
                        errorParse = true;
                        setError({ ...error, errorStyle: "Not a valid JSON." });
                      }
                      try {
                        const parsedKeys = JSON.parse(editorStateKeys);
                        keys = parsedKeys;
                      } catch (error) {
                        errorParse = true;
                        setError({ ...error, errorKeys: "Not a valid JSON." });
                      }
                      try {
                        const parsedClaims = JSON.parse(editorStateClaims);
                        claims = parsedClaims;
                      } catch (error) {
                        errorParse = true;
                        setError({
                          ...error,
                          errorClaims: "Not a valid JSON.",
                        });
                      }

                      console.log(
                        "KAYNA CONFIG PARSED:",
                        {
                          params: config,
                          style: style,
                          keys: keys,
                        },
                        errorParse
                      );

                      if (errorParse === false) {
                        updateKaynaConfigEditor({
                          params: config,
                          style: style,
                          keys: keys,
                          claims: claims,
                        });
                      }
                    }}
                  >
                    Update
                  </button>
                </div>

                <div className="w-full text-uppercase flex">
                  <button
                    className={`flex-1 py-2 text-center border-2 border-teal-800 cursor-pointer ${
                      JSONView === "false"
                        ? "border-teal-800 font-bold bg-teal-800 text-white"
                        : "bg-white"
                    } `}
                    onClick={() => setJSONView("false")}
                  >
                    Form View
                  </button>
                  <button
                    className={`flex-1 py-2 text-center border-2 border-teal-800 cursor-pointer ${
                      JSONView === "true"
                        ? "border-teal-800 font-bold bg-teal-800 text-white"
                        : "bg-white"
                    } `}
                    onClick={() => setJSONView("true")}
                  >
                    JSON View
                  </button>
                </div>

                {/* <div
                  onChange={handleSetJSONValue}
                  className="font-bold w-full flex justify-center gap-x-4 items-center"
                >
                  <div className="items-center">
                    <input
                      type="radio"
                      value="false"
                      checked={JSONView === "false"}
                      name="formView"
                      id="formView"
                    />{" "}
                    <label htmlFor="formView" className="ml-1">
                      Form View
                    </label>
                  </div>
                  <div className="items-center">
                    <input
                      style={{ marginLeft: "15px" }}
                      type="radio"
                      value="true"
                      checked={JSONView === "true"}
                      name="JSONview"
                      id="JSONview"
                    />
                    <label htmlFor="JSONview" className="ml-2">
                      JSON View
                    </label>
                  </div>
                </div> */}

                <div className="flex flex-row items-start justify-between">
                  {JSONView === "false" ? (
                    <div>
                      <div className="flex flex-row  md:flex-col">
                        <div className="my-[0.4vw] w-full mr-4">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Customer ID:
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="External Customer ID"
                            type="text"
                            value={JSON.parse(editorStateConfig)?.extCustomerID}
                            name="extCustomerID"
                            onChange={handleConfig}
                          />
                        </div>
                        <div className="my-[0.4vw] w-full">
                          <label className="block mb-2 text-sm font-medium text-gray-900">
                            Customer Name:
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Customer Name"
                            type="text"
                            value={
                              JSON.parse(editorStateConfig)?.extCustomerName
                            }
                            name="extCustomerName"
                            onChange={handleConfig}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row  md:flex-col">
                        <div className="my-[0.4vw] w-full mr-4">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Customer Email:
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="External Customer ID"
                            type="text"
                            value={
                              JSON.parse(editorStateConfig)?.extCustomerEmail
                            }
                            name="extCustomerEmail"
                            onChange={handleConfig}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row  md:flex-col">
                        <div className="my-[0.4vw] w-full mr-4">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Customer Email:
                          </label>
                          {/* <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="External Customer ID"
                            type="text"
                            value={
                              JSON.parse(editorStateConfig)?.extCustomerEmail
                            }
                            name="extCustomerEmail"
                            onChange={handleConfig}
                          /> */}
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 z-50 relative"
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                          >
                            <option value="kayna">Kayna UI</option>
                            <option value="broker">Broker UI</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="flex flex-row  md:flex-col">
                        <div className="my-[0.4vw] w-full mr-4">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Address:
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Address"
                            type="text"
                            value={
                              JSON.parse(editorStateConfig)?.items
                                ?.requiredParams?.address
                            }
                            name="address"
                            onChange={(e) =>
                              handleConfigItems(e, "requiredParams")
                            }
                          />
                        </div>
                        <div className="my-[0.4vw] w-full">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Building Area:
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Building Area"
                            type="number"
                            value={
                              JSON.parse(editorStateConfig)?.items
                                ?.requiredParams?.buildingArea
                            }
                            name="buildingArea"
                            onChange={(e) =>
                              handleConfigItems(e, "requiredParams")
                            }
                          />
                        </div>
                      </div> */}
                      {/* <div className="flex flex-row  md:flex-col"> */}
                      {/* <div className="m-[0.4vw]">
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Company Logo URL:
                    </label>
                    <input
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      placeholder="Logo URL"
                      type="text"
                      value={kaynaConfig?.logo}
                      name="logo"
                      onChange={updateKaynaConfig}
                    />
                  </div> */}
                      {/* <div className="my-[0.4vw] w-full mr-4">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Tenant Occupation:
                          </label>
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="tenantOccupation"
                            type="text"
                            value={
                              JSON.parse(editorStateConfig)?.items
                                ?.requiredParams?.tenantOccupation
                            }
                            name="tenantOccupation"
                            onChange={(e) =>
                              handleConfigItems(e, "requiredParams")
                            }
                          >
                            <option value="florist">Florist</option>
                            <option value="fireworks">Fireworks</option>
                          </select>
                        </div>
                        <div className="my-[0.4vw] w-full">
                          <label className="block mb-2 text-sm font-medium text-gray-900 ">
                            Inspection Date:
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            type="date"
                            value={
                              JSON.parse(editorStateConfig)?.items
                                ?.requiredParams?.electricalInspectionDate
                            }
                            name="electricalInspectionDate"
                            onChange={(e) =>
                              handleConfigItems(e, "requiredParams")
                            }
                          />
                        </div> */}
                      {/* </div> */}
                      <div className="flex flex-row md:flex-col w-full">
                        <div className="my-[0.4vw] w-full">
                          <label className="block mb-1 text-sm font-medium text-gray-900 ">
                            Primary Color:
                          </label>
                          <input
                            //   className="border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            className="w-full h-[30px] my-[8px]"
                            type="color"
                            value={JSON.parse(editorStateStyle)?.primaryColor}
                            name="primaryColor"
                            onChange={handleStyle}
                          />
                        </div>
                        <div className="my-[0.4vw] ml-[5vw] md:ml-0 w-full">
                          <label className="block mb-1 text-sm font-medium text-gray-900">
                            Secondary Color:
                          </label>
                          <input
                            //   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            className="w-full h-[30px] my-[8px]"
                            type="color"
                            value={JSON.parse(editorStateStyle)?.secondaryColor}
                            name="secondaryColor"
                            onChange={handleStyle}
                          />
                        </div>
                      </div>

                      <h2 className="text-center mt-4 font-bold">Claims</h2>
                      <div className="my-[0.4vw] w-full">
                        <label className="block mb-2 text-sm font-medium text-gray-900 ">
                          Claim ID:
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                          placeholder="Claim ID"
                          type="number"
                          value={JSON.parse(editorStateClaims)?.claimId}
                          onChange={(e) => handleClaimItems(e, null)}
                          name="claimId"
                        />
                      </div>

                      <div className="my-4 pb-8">
                        {JSON.parse(editorStateClaims)?.item?.map((c, idx) => (
                          <div className="bg-gray-300 px-4 py-2 mb-2" key={idx}>
                            {/* <div className="my-[0.4vw] w-full mb-2">
                              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Item ID:
                              </label>
                              <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Item ID"
                                type="number"
                                // value={
                                //   JSON.parse(editorStateConfig)?.items
                                //     ?.requiredParams?.buildingArea
                                // }
                                onChange={(e) =>
                                  handleClaimState(e, "claimItemId")
                                }
                                name="claimItemID"
                              />
                            </div> */}

                            <div className="my-[0.4vw] w-full mb-2">
                              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Name:
                              </label>
                              <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Name"
                                type="text"
                                value={c.name}
                                onChange={(e) => handleClaimItems(e, idx)}
                                name="name"
                              />
                            </div>
                            <div className="my-[0.4vw] w-full mb-2">
                              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Model:
                              </label>
                              <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Model"
                                type="text"
                                value={c.model}
                                onChange={(e) => handleClaimItems(e, idx)}
                                name="model"
                              />
                            </div>

                            <div className="my-[0.4vw] w-full mb-2">
                              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Description of Loss/Damage:
                              </label>
                              <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Description of Loss/Damage"
                                type="text"
                                value={c.issue}
                                onChange={(e) => handleClaimItems(e, idx)}
                                name="issue"
                              />
                            </div>

                            <div className="my-[0.4vw] w-full">
                              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                Damage Amount:
                              </label>
                              <input
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Damage Amount"
                                type="number"
                                value={c.damageAmount}
                                onChange={(e) => handleClaimItems(e, idx)}
                                name="damageAmount"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="min-w-[30vw] sm:max-w-[80vw] max-w-[30vw] flex flex-col">
                      <p className="font-bold text-black">Config:</p>
                      <div className="border-2 border-black rounded-xl my-4">
                        <Editor
                          value={editorStateConfig}
                          onValueChange={(code) => setEditorStateConfig(code)}
                          highlight={(code) => highlight(code, languages.js)}
                          padding={10}
                          style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                          }}
                          insertSpaces={true}
                        />
                      </div>
                      <p className="text-sm text-red-400">
                        {error.errorConfig}
                      </p>
                      <p className="font-bold text-black">Styles:</p>
                      <div className="border-2 border-black rounded-xl my-4">
                        <Editor
                          value={editorStateStyle}
                          onValueChange={(code) => setEditorStateStyle(code)}
                          highlight={(code) => highlight(code, languages.js)}
                          padding={10}
                          style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                          }}
                          insertSpaces={true}
                        />
                      </div>
                      <p className="text-sm text-red-400">{error.errorStyle}</p>
                      <p className="font-bold text-black">Keys:</p>
                      <div className="border-2 border-black rounded-xl my-4">
                        <Editor
                          value={editorStateKeys}
                          onValueChange={(code) => setEditorStateKeys(code)}
                          highlight={(code) => highlight(code, languages.js)}
                          padding={10}
                          style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                          }}
                          insertSpaces={true}
                        />
                      </div>
                      <p className="text-sm text-red-400">{error.errorKeys}</p>
                      <p className="font-bold text-black">Claims:</p>
                      <div className="border-2 border-black rounded-xl my-4">
                        <Editor
                          value={editorStateClaims}
                          onValueChange={(code) =>
                            JSON.parse(code)
                              ? setEditorStateClaims(code)
                              : setError({
                                  ...error,
                                  errorClaims: "Not a valid Json",
                                })
                          }
                          highlight={(code) => highlight(code, languages.js)}
                          padding={10}
                          style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                          }}
                          insertSpaces={true}
                        />
                      </div>
                      <p className="text-sm text-red-400">
                        {error.errorClaims}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
