import React, { useEffect, useState } from "react";
import { faker } from "@faker-js/faker";
import Modal from "./Modal";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip as ChartJsTooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ReactComponent as CyclingIcon } from "../Assets/icons/cyclingIcon.svg";
import { ReactComponent as CrossTrainingIcon } from "../Assets/icons/crossTrainingIcon.svg";
import { ReactComponent as HIITIcon } from "../Assets/icons/HIITIcon.svg";
import { ReactComponent as BoxingIcon } from "../Assets/icons/boxingIcon.svg";
import { ReactComponent as CalisthenicsIcon } from "../Assets/icons/calisthenicsIcon.svg";
import JamesAvatar from "../Assets/james-avatar.png";
import NoahAvatar from "../Assets/noah-avatar.png";
import LucasAvatar from "../Assets/lucas-avatar.png";
import LiamAvatar from "../Assets/liam-avatar.png";
import NewsOne from "../Assets/news-1.png";
import NewsTwo from "../Assets/news-2.png";

const todayClassesArr = [
  {
    icon: <CyclingIcon />,
    name: "cycling",
    time: "07:00",
  },
  {
    icon: <CrossTrainingIcon />,
    name: "Cross Training",
    time: "09:00",
  },
  {
    icon: <HIITIcon />,
    name: "HIIT",
    time: "12:00",
  },
  {
    icon: <BoxingIcon />,
    name: "Boxing",
    time: "17:00",
  },
  {
    icon: <CalisthenicsIcon />,
    name: "Calisthenics",
    time: "20:00",
  },
];

const Form = ({ open, setOpen }) => {
  const [token, setToken] = useState(null);
  const [platform, setPlatform] = useState("kayna");
  const [kaynaExists, setKaynaExists] = useState(false);
  const [kaynaClaims, setKaynaClaims] = useState({
    claimId: 33,
    item: [
      {
        name: "mobile",
        issue: "screen damage",
        model: "iphone 13 pro max",
        claimItemID: "1",
        damageAmount: "100",
        amountCurrency: "GBP",
      },
      {
        name: "Laptop2",
        issue: "Laptop damage1",
        model: "macbook air saas1 2020 13 inch",
        claimItemID: "2",
        damageAmount: "2000",
        amountCurrency: "GBP",
      },
    ],
  });
  const handleClaimState = (e, idx = null) => {
    if (idx === null) {
      console.log(e.target.value);
      setKaynaClaims({ ...kaynaClaims, claimId: e.target.value });
    } else {
      let claimItems = [...kaynaClaims.item];
      claimItems[idx][e.target.name] = e.target.value;
      setKaynaClaims({ ...kaynaClaims, item: claimItems });
    }
  };

  // const getPlatformKeys = () => {
  //   switch(process.env.REACT_APP_WIDGET_URL){
  //     case "dev-widget.kayna.io":
  //       return {
  //         access: "ecb38c80-3e02-11ed-9d7d-098d1ae76639",
  //         secret: "6S{P[ByT..|Nr52h",
  //         platformId: "10",
  //       }
  //     case "staging-widget.kayna.io":
  //       return {
  //         access: "ecb38c80-3e02-11ed-9d7d-098d1ae76639",
  //         secret: "6S{P[ByT..|Nr52h",
  //         platformId: "10",
  //       }
  //     default:
  //       return {
  //         access: "ecb38c80-3e02-11ed-9d7d-098d1ae76639",
  //         secret: "6S{P[ByT..|Nr52h",
  //         platformId: "10",
  //       }
  //   }
  // }

  const getRanHex = (size) => {
    let result = [];
    let hexRef = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
    ];

    for (let n = 0; n < size; n++) {
      result.push(hexRef[Math.floor(Math.random() * 16)]);
    }
    return result.join("");
  };

  const [kaynaConfig, setKaynaConfig] = useState({
    params: {
      extCustomerName: "Kumail Pirzada",
      extCustomerEmail: "Kumailp@zenveus.io",
      extCustomerID: getRanHex(12),
      applicationFormData: {
        "details-1": {
          applicationDetails: {
            contact: {
              name: "Peter",
              website: "https://www.peter.com",
              grossRevenue: 1200000,
            },
            quote: {
              limit: 100000000,
              retention: 250000,
            },
            others: {
              accepts_credit_cards: null,
              axis_disallowed_industries: {
                industries: ["None of the above"],
                explanation: "",
              },
              axis_next_gen_antivirus: true,
              allows_email_access_through_web_app_or_non_corporate_device: true,
              allows_remote_access: true,
              aware_of_claim: {
                bool: false,
              },
              backup_protected_by_mfa: true,
              backups_less_than_1_month_old: null,
              biometrics: false,
              six_hour_outage_within_past_3_years: {
                bool: false,
              },
              claims_legal_or_regulatory_actions_past_5_years: {
                bool: false,
              },
              common_cyber_disallowed_industries_with_explanation: {
                industries: ["None of the above"],
                explanation: null,
              },
              company_gross_revenue: {
                amount: 50000000,
                currency: "USD",
              },
              controls_wire_transfers_over_25k: true,
              critical_info_backup_cadence: "Weekly",
              critical_system_rto: "1 - 2 days",
              critical_patch_frequency: "1-5 days",
              email_provider: {
                selection: "Google Suite (Gmail)",
              },
              email_security_software: {
                selections: [
                  "AppRiver",
                  "Avanan Cloud Email Security",
                  "Barracuda Essentials",
                  "Cisco Secure Email",
                ],
                others: null,
              },
              email_security_measures_v2: [
                "Screening for malicious attachments",
                "Screening for malicious links",
                "Tagging external emails",
              ],
              mfa_multiple: ["Email"],
              encrypt_email: null,
              encrypt_mobile_devices: null,
              encrypt_pos_terminals: null,
              encrypted_backups: true,
              endpoint_protection_for_all_devices: true,
              entity_name: "QA Account Test Co",
              estimated_pii_records: "0 - 250,000",
              estimated_total_records: "0 - 50,000",
              franchise: null,
              gross_revenue_next_12_months: {
                amount: 50000000,
                currency: "USD",
              },
              has_backups: true,
              has_email_filtering: true,
              has_end_of_life_software_on_network: false,
              has_offline_or_cloud_backups: true,
              income_loss_from_downtime: false,
              industry: {
                title: "Other Nonhazardous Waste Treatment and Disposal",
                naics_code_2017: "562219",
              },
              ip_infringement_prevention_and_takedown_process: true,
              ip_infringement_prevention_supervised_by_attorney: true,
              it_system_update_frequency: "Weekly",
              mailing_address: {
                street_line_1: "700 Bush St.",
                street_line_2: "asdas",
                city: "San Francisco",
                state: "CA",
                zip_code: "94108",
              },
              prior_coverage: null,
              physical_address_same_as_mailing: true,
              physical_address: {
                street_line_1: "700 Bush St.",
                street_line_2: "asdas",
                city: "San Francisco",
                state: "CA",
                zip_code: "94108",
              },
              mfa_for_email: true,
              mfa_for_privileged_user_accounts: true,
              mfa_for_remote_access: true,
              non_critical_patch_frequency: "Weekly",
              other_websites: null,
              out_of_band_authentication: null,
              ownership_type: null,
              patches_within_2_months: true,
              pci_compliance_with_na: null,
              pci_compliant_payments: null,
              prevent_unauthorized_employee_wire_transfers: null,
              primary_contact: {
                name: "John Doe",
                email: "john@example.com",
                acts_as_designated_security_contact: true,
              },
              primary_contact_phone_number: "+12125552368",
              primary_contact_position: "Advisor",
              primary_website: "http://requestbin.com",
              requires_third_party_security: true,
              rightful_media_use: null,
              segregated_networks: true,
              tested_backups_within_6_months: true,
              tested_failover: true,
              total_employees: 10,
              verify_bank_accounts: null,
              year_established: "2015",
              mfa_provider: {
                other: null,
                selection: "Auth0",
              },
              backup_protected_by_mfa_cfc_title_escrow: null,
              anti_virus_provider: {
                other: null,
                selection: "Windows Defender Endpoint",
              },
              edr_provider: {
                other: null,
                selection: "BitDefender",
              },
              loss_of_records_past_5_years: null,
              claims_legal_or_regulatory_actions_most_recent: null,
              claims_legal_or_regulatory_actions_past_5_years_amount: null,
              additional_named_insureds: null,
              business_activities: null,
            },
          },
          previewConfig: {
            hide: ["others"],
          },
          productId: "5088e71f-30fa-486b-a901-bfcf8f81fc29",
        },
      },
      // items: {
      //   requiredParams: {
      //     buildingArea: "2",
      //     address: "London",
      //     electricalInspectionDate: "2021-10-20",
      //     tenantOccupation: "fireworks",
      //   },
      //   otherParams: {},
      // },
      // applicationFormData: {
      //   contact: {
      //     firstName: "dawood",
      //     lastName: "sadiq",
      //     email: "dawood.sadiq@zenveus.com",
      //     phone: "(254) 234-2453",
      //   },
      //   quote: {
      //     effectiveDate: "2023-10-06",
      //     deductibleAcceptReject: true,
      //     // deductibleSignature: "dawood",
      //     deductibleTitle: "dawood",
      //     deductibleAmount: 3000,
      //     // officerName: "dada",
      //     digitalSign: false,
      //     tosConfirm: true,
      //   },
      //   business: {
      //     legalName: "kayna",
      //     legalEntityType: "CORP",
      //     fein: "231910873",
      //     unemploymentId: "234",
      //     yearStarted: 1990,
      //     website: "kayna.io",
      //     operationsDescription: "kayna widget",
      //   },
      //   locations: {
      //     primaryFlag: true,
      //     addressLine1: "lalala",
      //     city: "los angeles",
      //     state: "CA",
      //     zip: "90002",
      //     website: "kayna.io",
      //   },
      //   rateClasses: {
      //     naicsCode: "541511",
      //     naicsDescription:
      //       "Custom Computer Programming Services 541511 Custom Computer Programming Services: This U.S. industry comprises establishments primarily engaged in writing, modifying, testing, and supporting software to meet the needs of a particular customer.",
      //     stateClass: "smoker",
      //     stateClassSequence: "SC001",
      //     stateClassDescription: "Low-Risk State Class",
      //     numberOfEmployees: 50,
      //     annualPayroll: 2000000,
      //     numberOfOfficers: 23,
      //   },
      //   officers: {
      //     includeExclude: true,
      //     firstName: "John",
      //     lastName: "Doe",
      //     title: "CEO",
      //     salary: 150000,
      //     ownershipPercent: 40,
      //   },
      //   priorLosses: {
      //     claimsOneYearBefore: 0,
      //     claimsTwoYearsBefore: 0,
      //     claimsThreeYearsBefore: 0,
      //     claimsFourYearsBefore: 0,
      //     lostTimeClaimsOneYearBefore: 0,
      //     lostTimeClaimsTwoYearsBefore: 0,
      //     lostTimeClaimsThreeYearsBefore: 0,
      //     lostTimeClaimsFourYearsBefore: 0,
      //   },
      //   questions: [],
      // },
    },
    style: {
      width: "100%",
      height: "455px",
      primaryColor: "#7F35B2",
      secondaryColor: "#00FF00",
      // theme: "night",
      // logo: 'https://dev-widget-kayna.netlify.app/favicon.ico',
    },
    keys: {
      token: token,
      widgetType: "kayna",
      platformId: process.env.REACT_APP_KAYNA_PLATFORM_ID,
    },
    claims: {
      claimId: 33,
      item: [
        {
          name: "mobile",
          issue: "screen damage",
          model: "iphone 13 pro max",
          claimItemID: "1",
          damageAmount: "100",
          amountCurrency: "GBP",
        },
        {
          name: "Laptop2",
          issue: "Laptop damage1",
          model: "macbook air saas1 2020 13 inch",
          claimItemID: "2",
          damageAmount: "2000",
          amountCurrency: "GBP",
        },
      ],
    },
  });

  const updateKaynaConfig = (e) => {
    e.preventDefault();
    let kaynaConfigTemp = { ...kaynaConfig };
    kaynaConfigTemp.params[e.target.name] = e.target.value;
    setKaynaConfig(kaynaConfigTemp);
  };

  const updateKaynaConfigItems = (e, type = "requiredParams") => {
    e.preventDefault();
    let kaynaConfigTemp = { ...kaynaConfig };
    kaynaConfigTemp.params.items[type][e.target.name] = e.target.value;
    setKaynaConfig(kaynaConfigTemp);
  };

  const updateKaynaConfigStyle = (e) => {
    e.preventDefault();
    let kaynaConfigTemp = { ...kaynaConfig };
    kaynaConfigTemp.style[e.target.name] = e.target.value;
    setKaynaConfig(kaynaConfigTemp);
  };

  const updateKaynaConfigEditor = (kaynaConfigEditor) => {
    console.log("WORKING");
    setKaynaConfig(kaynaConfigEditor);
  };

  const updateWidget = () => {
    console.log("UPDATING");
    console.log("Claims: ", kaynaClaims);
    window.Kayna.init(
      {
        ...kaynaConfig.params,
        claims: [kaynaConfig?.claims],
      },
      {
        style: kaynaConfig.style,
        keys: {
          ...kaynaConfig?.keys,
          token: kaynaConfig?.keys?.token
            ? kaynaConfig?.keys?.token
            : token
            ? token
            : null,
        },
      }
    );
    setOpen(false);
  };

  useEffect(() => {
    console.log("PLATFORM", platform);
    if (platform === "kayna") {
      setKaynaConfig({
        ...kaynaConfig,
        keys: {
          ...kaynaConfig?.keys,
          platformId: process.env.REACT_APP_KAYNA_PLATFORM_ID,
          widgetType: "kayna",
        },
      });
    } else {
      setKaynaConfig({
        ...kaynaConfig,
        keys: {
          ...kaynaConfig?.keys,
          platformId: process.env.REACT_APP_BROKER_PLATFORM_ID,
          widgetType: "broker",
        },
      });
    }
    //eslint-disable-next-line
  }, [platform]);

  // useEffect(() => {
  //   updateWidget();
  //   // eslint-disable-next-line
  // }, [kaynaConfig]);

  useEffect(() => {
    if (kaynaExists) {
      updateWidget();
    }
    // eslint-disable-next-line
  }, [kaynaExists]);

  useEffect(() => {
    console.log("UPDATING PLATFORMCONFIG", kaynaConfig);
    if (!kaynaConfig?.keys?.token && !token) return;

    let kaynaCheckInterval = null;
    if (!kaynaExists) {
      kaynaCheckInterval = setInterval(() => {
        if (window?.Kayna.init && !kaynaExists) setKaynaExists(true);
      }, 500);
    } else {
      updateWidget();
    }

    return () => kaynaCheckInterval && clearInterval(kaynaCheckInterval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kaynaConfig, token]);

  useEffect(() => {
    if (
      kaynaConfig?.keys?.platformId ===
        process.env.REACT_APP_KAYNA_PLATFORM_ID ||
      kaynaConfig?.keys?.platformId === process.env.REACT_APP_BROKER_PLATFORM_ID
    ) {
      setToken(null);
      fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        body: JSON.stringify({
          accessKey:
            platform === "kayna"
              ? process.env.REACT_APP_KAYNA_ACCESS
              : process.env.REACT_APP_BROKER_ACCESS,
          secret:
            platform === "kayna"
              ? process.env.REACT_APP_KAYNA_SECRET
              : process.env.REACT_APP_BROKER_SECRET,
          extCustomerId: kaynaConfig?.params?.extCustomerID,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(
            "TOKEN SET",
            data?.token,
            kaynaConfig?.params?.extCustomerID
          );
          setToken(data.token);
          console.log("PLATFORMCONFIG keys", kaynaConfig.keys);
          setKaynaConfig({
            ...kaynaConfig,
            keys: { ...kaynaConfig?.keys, token: data.token },
          });
        });
    }
    //eslint-disable-next-line
  }, [kaynaConfig?.params?.extCustomerID, kaynaConfig?.keys?.platformId]);

  ChartJS.register(RadialLinearScale, ArcElement, ChartJsTooltip, Legend);

  const data = [
    {
      name: "Jan",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
    {
      name: "Feb",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
    {
      name: "Mar",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
    {
      name: "Apr",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
    {
      name: "May",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
    {
      name: "Jun",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
    {
      name: "Jul",
      sales: faker.number.int({ min: 14500, max: 25000 }),
    },
  ];

  return (
    <div className="w-full flex flex-col items-center mt-[1vw] h-screen sm:pr-7">
      <div className="w-full flex items-start justify-between bg-[#F5F7FA]">
        <div className="grid gap-6 px-6 w-full grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2">
          <div className="flex flex-col px-4 py-5 bg-white rounded-2xl w-full h-[455px]">
            <span className="font-semibold text-xl text-[#1F2751]">
              Today Classes
            </span>
            <div className="flex flex-col w-full divide-y-2 divide-[#F5F6FB] mt-4">
              {todayClassesArr?.map((cl) => {
                return (
                  <div className="flex items-center justify-between py-4">
                    <div className="flex items-center gap-x-4">
                      <span>{cl.icon}</span>
                      <span className="text-sm text-[#1F2751] font-bold capitalize">
                        {cl.name}
                      </span>
                    </div>
                    <span className="text-[15px] text-[#1F2751] font-medium">
                      {cl.time}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="w-full h-full">
            <div id="kayna" />
          </div>

          <div className="flex flex-col px-4 py-5 bg-white rounded-2xl w-full h-[455px] overflow-hidden">
            <span className="font-semibold text-xl text-[#1F2751]">Sales</span>
            <div className="mt-8 w-full h-full min-h-[400px] flex items-center">
              <ResponsiveContainer width="100%" height="80%">
                <AreaChart data={data}>
                  <defs>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#4379EE" stopOpacity={0.6} />
                      <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="name" axisLine={false} />
                  <YAxis
                    dataKey="sales"
                    tickFormatter={(i) => `${(i / 1000).toFixed(0)}K`}
                    axisLine={false}
                  />
                  <CartesianGrid vertical={false} />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="sales"
                    stroke="#4880FF"
                    fillOpacity={1}
                    fill="url(#colorPv)"
                    dot={{ stroke: "#4880FF", fill: "#4880FF", strokeWidth: 2 }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="flex flex-col px-4 py-5 bg-white rounded-2xl w-full h-[455px] overflow-hidden">
            <span className="font-semibold text-xl text-[#1F2751]">
              Customer Satisfaction
            </span>
            <div className="mt-8 w-full h-full flex items-center">
              <PolarArea
                height={300}
                data={{
                  labels: [
                    "Satisfied",
                    "Very Satisfied",
                    "Neutral",
                    "Insatisfied",
                  ],
                  datasets: [
                    {
                      // label: "# of Votes",
                      data: [1, 2, 3, 4].map((i) =>
                        faker.number.int({ min: 14500, max: 25000 })
                      ),
                      backgroundColor: [
                        "#4880FF",
                        "#16DBCC",
                        "#FFBB38",
                        "#FF82AC",
                      ],
                      borderWidth: 1,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    r: {
                      ticks: {
                        display: false, // Remove vertical numbers
                      },
                      grid: {
                        display: false, // Removes the circulair lines
                        circular: true,
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-3/12 hidden md:flex">
          <div className="flex flex-col justify-between w-full gap-y-6">
            <div className="flex flex-col gap-y-6 py-4 bg-white rounded-2xl w-full h-full">
              <div className="flex items-center justify-between px-5">
                <span className="font-semibold text-xl text-[#1F2751]">
                  Messages
                </span>
                <button className="bg-transparent border-none text-[#4880FF] font-semibold text-sm">
                  See All
                </button>
              </div>

              <div className="flex flex-col divide-2 divide-[#F5F6FB]">
                {[
                  {
                    avatar: JamesAvatar,
                    name: "James Robinson",
                    date: "3h ago",
                    message: "Hey, just checking in to...",
                  },
                  {
                    avatar: NoahAvatar,
                    name: "Noah Taylor",
                    date: "4h ago",
                    message: "Hi, I noticed some muscle...",
                  },
                  {
                    avatar: LucasAvatar,
                    name: "Lucas Rodriguez",
                    date: "Yesterday",
                    message: "Hey, I've been experiencing...",
                  },
                  {
                    avatar: LiamAvatar,
                    name: "Liam Wilson",
                    date: "2d ago",
                    message: "Could you help me set some...",
                  },
                ].map((message) => {
                  return (
                    <div className="flex items-start gap-x-3 px-5 py-3">
                      <img
                        src={message?.avatar}
                        alt="avatar"
                        className="w-9 h-9 rounded-full"
                      />
                      <div className="flex flex-col gap-y-1.5">
                        <span className="text-sm font-bold text-[#1F2751]">
                          {message?.name}
                        </span>
                        <span className="text-xs text-[#6A7091]">
                          {message?.date}
                        </span>
                        <span className="text-xs text-[#1F2751] mt-1">
                          {message?.message}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col  py-4 bg-white rounded-2xl w-full h-full overflow-hidden">
              <div className="flex items-center justify-between px-5">
                <span className="font-semibold text-xl text-[#1F2751]">
                  Latest News
                </span>
                <button className="bg-transparent border-none text-[#4880FF] font-semibold text-sm">
                  See All
                </button>
              </div>

              <div className="flex flex-col divide-2 divide-[#F5F6FB]">
                <div className="w-full flex flex-col items-center py-6">
                  <img src={NewsOne} alt="news" />
                  <span className="text-[15px] font-bold text-[#1F2751] mt-4">
                    Strength Training for Women
                  </span>
                </div>
                <div className="w-full flex flex-col items-center">
                  <img src={NewsTwo} alt="news" />
                  <span className="text-[15px] font-bold text-[#1F2751] mt-4">
                    Strength Training for Women
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-screen">
        <Modal
          open={open}
          setOpen={setOpen}
          updateWidget={updateWidget}
          updateKaynaConfig={updateKaynaConfig}
          kaynaConfig={kaynaConfig}
          updateKaynaConfigStyle={updateKaynaConfigStyle}
          updateKaynaConfigItems={updateKaynaConfigItems}
          updateKaynaConfigEditor={updateKaynaConfigEditor}
          kaynaClaims={kaynaClaims}
          handleClaimState={handleClaimState}
          platform={platform}
          setPlatform={setPlatform}
        />
      </div>
    </div>
  );
};

export default Form;
