import React from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";

const DashboardLayout = ({
  children,
  openSidebar,
  setOpenSidebar,
  setOpenModal,
  openModal,
}) => {
  return (
    <div className="w-full flex flex-col">
      <div className={openSidebar ? "" : "pl-0 md:pl-[14rem]"}>
        <Header
          setOpenSidebar={setOpenSidebar}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      </div>
      <div className="w-full flex items-start justify-between">
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
        <div className="w-screen pl-0 md:pl-[14rem]">{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
