import React from "react";
import ProfileImg from "../Assets/avatar.png";
import { ReactComponent as SearchIcon } from "../Assets/icons/searchIcon.svg";
import { ReactComponent as DropdownIcon } from "../Assets/icons/langDrop.svg";
import { ReactComponent as HamburgerIcon } from "../Assets/icons/hamburgerIcon.svg";

const Header = ({ setOpenSidebar, setOpenModal, openModal }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-x-2 px-4 py-3 bg-white border-b-2 border-[#EBEDF7]">
      <div className="flex items-center gap-x-2">
        <div className="visible md:invisible">
          <button
            className="py-3 px-5 rounded-lg bg-[#E7EEFF] text-[#4880FF]"
            onClick={() => {
              console.log("Clicked");
              setOpenSidebar(true);
            }}
          >
            <HamburgerIcon />
          </button>
        </div>
        <div className="flex flex-row items-center bg-[#F9FAFC] py-2 rounded-full w-full border-2 border-[#EBEDF7] -ml-0 md:-ml-16">
          <SearchIcon className="w-6 ml-4" />
          <input
            type="text"
            placeholder="Search"
            className="border-0 ml-4 bg-[#FCFCFC] text-[#BBBBBB] text-lg focus:outline-0 w-[100%]"
          />
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <button
          className="py-3 px-5 rounded-lg bg-[#E7EEFF] text-[#4880FF]"
          onClick={() => setOpenModal(!openModal)}
        >
          Form
        </button>

        <div className="items-center gap-x-5 w-[10rem] hidden sm:flex">
          <img src={ProfileImg} alt="profile-avatar" />
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-col gap-y-1">
              <span className="text-sm text-[#1F2751] font-bold">Anddy</span>
              <span className="text-xs text-[#565656]">Admin</span>
            </div>
            <DropdownIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
